<template>
  <div>
    <header-one />
    <main>
      <slot />
    </main>
    <footer-one />
    <back-to-top />
    <!-- <modal-product /> -->
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/pinia/useAuthStore";
import { useCheckoutStore } from "@/pinia/useCheckoutStore";
import { useCurrencyStore } from "@/pinia/useCurrencyStore";
import { useHomeStore } from "@/pinia/useHomeStore";
import { useUtilityStore } from "@/pinia/useUtilityStore";

const { getBrands, getContactInfo, getPages } = useHomeStore();
const { getAllCurrencies } = useCurrencyStore();
const { getCheckoutData } = useCheckoutStore();

const { getCategories } = useHomeStore();
await Promise.all([
  // getBrands(),
  getAllCurrencies(),
  getCategories(),
  // getCheckoutData(),
  // getContactInfo(),
  // getPages(),
]);
const { locale } = useI18n();
const htmlAttrs = computed(() => ({
  dir: locale.value === "ar" ? "rtl" : "ltr",
  lang: locale.value,
}));

const route = useRoute();
useHead(() => ({
  htmlAttrs,
  link: [
    {
      rel: "canonical",
      href: "https://swift-ecommerce.urusglobal.com" + route.path,
    },
  ],
}));
</script>
<style>
html[dir="rtl"] * {
  font-family: Montserrat, sans-serif !important;
}
</style>
